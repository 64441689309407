import Abubakar_Sadeeq_Adamu from './../assets/core-team/Abubakar_Sadeeq_Adamu.png';
import Ahmad_Umar from './../assets/core-team/Ahmad_Umar.png';
import OLAGUNJU_ABRAHAM from './../assets/core-team/OLAGUNJU_ABRAHAM.png';
import USMAN_MAHMOOD from './../assets/core-team/USMAN_MAHMOOD.png';
import Balikis_Olukade from './../assets/core-team/Balikis_Olukade.png';
import Abioye_Yetunde from './../assets/core-team/Abioye_Yetunde.png';
import Nabila_Salisu from './../assets/core-team/Nabila_Salisu.jpg';

const team = [
  {
    name: 'Umar Ahmad',
    designation: 'Founder',
    bio: "Umar Ahmad is an accomplished computational genomicist and the Head of the Anatomy Department at Bauchi State University, Nigeria. With a distinguished career in genetics and a focus on the regulatory pathways driving tumour recurrence and progression, Ahmad's pioneering work primarily revolves around bladder cancer genomics. He boasts a profound skill set in managing large scale genomic data, developing R/Bioconductor packages and utilising high-throughput sequence data analysis tools. A passionate Open Science advocate, he founded the Open Science Community Nigeria (OSCN) and is actively involved with AfricArXiv, promoting transparency and collaboration in scientific research.",
    image: Ahmad_Umar,
    social: {
      ORCID: 'https://orcid.org/0000-0002-3216-5171',
      twitter: 'https://twitter.com/babasaraki1',
      linkedin: 'https://www.linkedin.com/in/babasaraki/',
      github: 'https://github.com/babasaraki',
      mastodon: 'https://mastodon.social/@babasaraki',
      email: 'mailto:babasaraki@yahoo.co.uk',
    },
  },
  {
    name: 'Nabila Salisu',
    designation: 'Community Lead and Global & Partnership Coordinator',
    bio: "Nabila Salisu is a dedicated public health policy Analyst with a Master of Science in Biostatistics and an MPH in Health Policy from the NYU School of Global Public Health. Additionally, she is a Certified Data Scientist from Massachusetts Institute of Technology. With extensive experience in epidemiology, she has significantly contributed to the Connecticut Department of Public Health by recoding three years of Legionnaires' disease data to adhere to CDC standards, conducting epidemiological investigations, and enhancing surveillance efforts. Her work focuses on improving public health outcomes through accurate data analysis and effective outbreak response strategies. Originally from Kano, Nigeria, and being multinational, Nabila is passionate about open science and is actively involved with the Open Science Community Nigeria (OSCN), where she promotes transparency, collaboration, and the sharing of scientific knowledge to improve research quality in Nigeria.",
    image: Nabila_Salisu,
    social: {
      linkedin: 'https://www.linkedin.com/in/nabila-salisu-62ab0192/',
      github: 'https://github.com/Nabilasalisu',
      email: 'mailto:ns5648@nyu.edu',
    },
  },
  {
    name: 'Mahmood Usman',
    designation: 'Regional Coordinator (Northeast)',
    bio: 'Mahmood Usman is a Lecturer at the Yusuf Maitama Sule University, Kano and a PhD student of Ahmadu Bello University, working in the BioRTC Laboratory in Yobe State University. His work is in the field of cognitive neuroscience, trying to understand the interaction between environmental neurotoxins in modifying cognitive behaviour in rat model. He is also very enthusiastic about open access which lead him into Open Science Community Nigeria (OSCN), promoting collaboration, openness and transparency in science.',
    image: USMAN_MAHMOOD,
    social: {
      ORCID: 'https://orcid.org/0000-0002-0273-9867',
      twitter: 'https://twitter.com/usmood',
      github: 'https://github.com/usmood',
      email: 'mailto:mahmoodusman13@gmail.com',
    },
  },
  {
    name: 'Adamu Abubakar Sadeeq',
    designation: 'Regional Coordinator (Northwest)',
    bio: "I am Adamu Abubakar Sadeeq, Ph.D., I obtained my bachelor's degree in human anatomy with a major in neuroscience from Ahmadu Bello University, (ABU) Nigeria, in 2010 and my MSc in 2012 which focused on the analysis of Mercury toxicity in the brain using neutron activation analysis (NAA-1) and evaluating cognitive and motor impairment due to heavy metal (mercury) exposure. I completed my PhD in 2017, where my research addressed low mercury exposure on the central nervous system. I  studied how mercury could lead to beta-amyloid accumulation, alteration of neurotransmitters in pathological conditions. Currently I have a  teaching position with the neuroscience unit of Human Anatomy Department, Ahmadu Bello University, Nigeria.",
    image: Abubakar_Sadeeq_Adamu,
    social: {
      ORCID: 'https://orcid.org/0000-0001-5883-3425',
      twitter: 'https://twitter.com/aasadeequ',
      linkedin: 'https://www.linkedin.com/in/sadeeq-adamu-16616677/',
    },
  },
  {
    name: 'Yetunde Abioye',
    designation: 'Regional Coordinator (Northcentral)',
    bio: 'Yetunde Abioye is a veterinarian working in transboundary fields across the human-animal-interface. Currently, she works with the Nigeria Center for Disease Control and Prevention (NCDC) as an epidemiologist and One health officer. Additionally, she coordinates the preparedness, readiness and response strategies in the control and management of Lassa fever as the National Incident manager of the technical Working Group (TWG). Since she joined the One Health division of the surveillance and epidemiology of the NCDC, she has supported implementation of the key activities in the National One Health Strategic Plan (NOHSP) and made presentations at international conferences and meetings. Additionally, she was appointed as a member of the African Union One Health Information Architecture TWG (OH-IA TWG) to guide digitized One Health governance and management in Africa. Despite protecting the health of Nigerians, she finds time to mentor a group of veterinarians through a mentoring platform. This is  due to her tireless commitment to development initiatives for youths.',
    image: Abioye_Yetunde,
    social: {
      ORCID: 'https://orcid.org/0000-0001-6091-200X',
      twitter: 'https://twitter.com/abioyeyetuns',
      linkedin: 'https://www.linkedin.com/in/yetunde-abioye-711903173/',
    },
  },
  {
    name: 'Baliqis A. Olukade',
    designation: 'Regional Coordinator (Southwest)',
    bio: 'Baliqis A. Olukade is a neuroscience enthusiast and passionate about improving therapeutic decisions in global bio-sciences research and imparting knowledge. She is currently pursuing her Ph.D. degree in Medical Science Program at Morsani College of Medicine, University of South Florida (USF), USA. Her research interest encompasses Behavior and Cognitive Aging, Computational Neuroscience, Individual Differences, and General Physiology.  Her professional affiliations include faculty in the Department of Physiology, Faculty of Basic Medical Sciences, Olabisi Onabanjo University, Nigeria and at the Basic Medical Sciences (BMS) Department, Open International College of Naturopathic Medicine (OICNM), Nigeria. In addition to her research authorships and contributions to scientific discussions, she is a member of the Neuroscience Society of Nigeria (NSN), the Society of Neuroscientists of Africa (SONA), Women in Neuroscience (WiN) Nigeria, and World Women in Neuroscience (WWN). A previous highlight of her career was winning three sponsored International Brain Research Organization schools in Ghana, Teaching Tools in Neuroscience, Neurobiology of Stress Symposium at the University of Ibadan. She is also an alumna of the IBRO-Simons Computational Neuroscience Imbizo (IsiCNI) in South Africa.',
    image: Balikis_Olukade,
    social: {
      ORCID: 'https://orcid.org/0000-0001-8372-8846',
      twitter: 'https://twitter.com/BOlukade',
      linkedin: 'https://www.linkedin.com/in/baliqis-olukade-8b6264185/',
      email: 'mailto:olukadebaliqis@gmail.com',
    },
  },
  {
    name: 'Abraham Olagunju',
    designation: 'Student Assistant (Web Development & Maintenance)',
    bio: 'Abraham OLAGUNJU is a skilled software engineer with a strong commitment to openness and innovation, with a strong educational background in Computer Science from Ladoke Akintola University of Technology, class of 2019, and ongoing studies in software engineering, he excels in developing cutting-edge solutions and leveraging emerging technologies. Passionate about open science initiatives, Abraham actively promotes transparency and collaboration in his work and research. With a dedication to embracing openness, he contributes to advancing knowledge and driving innovative solutions in the field of software engineering.',
    image: OLAGUNJU_ABRAHAM,
    social: {
      ORCID: 'https://orcid.org/0009-0009-2366-1868',
      twitter: 'https://twitter.com/OluwaninsolaAO',
      linkedin: 'https://www.linkedin.com/in/oluwaninsolaao/',
      github: 'https://github.com/OluwaninsolaAO',
      mastodon: 'https://mastodon.social/@oluwaninsolaao',
      email: 'mailto:olagunjusola070@gmail.com',
    },
  },
];

export default team;
