import React from 'react';
import { useParams } from 'react-router';
import Posts from '../../data/posts.js';
import NotFound from '../404';
import PostsSidebar from './posts-sidebar';
import { safeHtml } from '../../utils/sanitizeHtml';
import { FaCalendar, FaPen } from 'react-icons/fa';
import { setPageTitle } from '../../utils/PageTitle';

export default function SinglePost() {
  let { title } = useParams();
  title = decodeURIComponent(title);
  title = title.replaceAll('-', ' ');

  const post = getPost();

  function getPost() {
    for (const post of Posts) {
      if (post.title === title) {
        setPageTitle(post.title);
        return post;
      }
    }
    return undefined;
  }

  if (post === undefined) {
    return <NotFound more="posts" />;
  } else {
    return (
      <>
        <div className="container pt-5">
          <div
            className="container-fluid row justify-content-between"
            style={{ margin: '0px' }}
          >
            <div className="col-lg-8 main-content">
              <div className="mb-3">
                <h1 className="display-5">{post.title}</h1>
                <div className="d-flex">
                  <small className="text-muted my-2 me-3">
                    <FaCalendar style={{ marginRight: '10px' }} /> {post.date}
                  </small>
                  <small className="text-muted my-2 me-3">
                    <FaPen style={{ marginRight: '10px' }} /> {post.author}
                  </small>
                </div>
                <img
                  src={post.image}
                  alt={post.title}
                  style={{ width: '100%', padding: '3rem 0' }}
                />
              </div>
              <section
                dangerouslySetInnerHTML={{
                  __html: safeHtml(post.content),
                }}
              ></section>
            </div>
            <PostsSidebar />
          </div>
        </div>
      </>
    );
  }
}
