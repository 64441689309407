import React from 'react';
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';
import './styles.css';
import global from '../../data/global.json';
import { Link } from 'react-router-dom';

export default function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div className="footer mt-5">
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-5 px-4">
          <div className="col-md-4 col-12 align-items-center">
            <p className="mb-3 mb-md-0">
              © {currentYear} {global.brand.name}
            </p>
            <ul className="justify-content-end list-unstyled mt-2 footer-nav">
              <li>
                <Link to="/code_of_conduct">Code of Conduct</Link>
              </li>
              {/* <li>
                <Link to="/terms_of_agreement">Terms of Agreement</Link>
              </li> */}
              <li>
                <Link to="/contact">Contact OSCN</Link>
              </li>
            </ul>
          </div>

          <ul className="col-md-4 justify-content-end list-unstyled d-flex">
            <li>
              <a
                className="text-white text-decoration-none"
                href={global.socials.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size="1.5rem" />
              </a>
            </li>
            <li className="ms-3">
              <a
                className="text-white text-decoration-none"
                href={global.socials.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin size="1.5rem" />
              </a>
            </li>
            <li className="ms-3">
              <a
                className="text-white text-decoration-none"
                href={global.socials.github}
                target="_blank"
                rel="noreferrer"
              >
                <FaGithub size="1.5rem" />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
}
