import React, { useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import { safeText } from '../../utils/sanitizeHtml';

import './react-calendar.css';
import events_lone from '../../data/events.json';
import posts from '../../data/posts.js';
import { setPageTitle } from '../../utils/PageTitle';

export default function Events() {
  setPageTitle('Events');
  const posts_p = posts.map((value) => {
    value.description = safeText(value.content);
    value.url =
      '/posts/' + encodeURIComponent(value.title.replaceAll(' ', '-'));
    return value;
  });
  const events_json = useMemo(() => {
    return [...events_lone, ...posts_p];
  }, [posts_p]);

  const [events, setEvents] = useState(
    [...events_lone, ...posts_p].filter((event) => {
      const now = new Date();
      const event_date = new Date(event.date);
      if (event_date > now) {
        return true;
      }
      return false;
    })
  );

  function tileClassName({ date, view }) {
    const event = events_json.find((event) => {
      const check = new Date(event.date);
      return check.getTime() === date.getTime();
    });
    if (event) {
      return 'hasEvent';
    }
  }

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col pt-md-2 order-md-1 order-2">
            <div className="container-fluid">
              <div className="list-group">
                <ListEvents events={events} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 pb-5 order-md-2 order-1">
            <div className="container-fluid">
              <Calendar
                minDetail="month"
                onChange={(date) => {
                  setEvents(
                    events_json.filter((event) => {
                      const event_date = new Date(event.date);
                      if (event_date.getTime() === date.getTime()) {
                        return true;
                      }
                      return false;
                    })
                  );
                }}
                tileClassName={tileClassName}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ListEvents({ events }) {
  if (events.length) {
    return events.map((event, index) => {
      return (
        <div className="card mb-3" key={index}>
          <Link
            to={event.url}
            className="list-group-item list-group-item-action lh-tight px-0"
          >
            <div className="card-body">
              <h5 className="card-title post-card-title">{event.title}</h5>
              <p className="card-text post-card-text">{event.description}</p>
              <p className="card-text">
                <small className="text-muted">{event.date}</small>
              </p>
            </div>
          </Link>
        </div>
      );
    });
  } else {
    return (
      <div className="card mb-3">
        <div className="list-group-item list-group-item-action lh-tight px-0">
          <div className="card-body">
            <h5 className="card-title">No activity or events found.</h5>
            <p className="card-text text-muted">
              We're sorry, but it looks like there are no events scheduled for
              this day. Please check back soon for updates.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
