import React, { useState } from 'react';
import { setPageTitle } from '../../utils/PageTitle';
import api from '../../api';
import { showToast } from '../../components/Toast';

export default function Join() {
  setPageTitle('Join Us');
  const [processing, setProcessing] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const contact_me_for = [
    'Open Access',
    'Sharing Data',
    'FAIR Data',
    'Open Source Software',
    'Sharing Code',
    'Working with GitHub',
    'PlanS',
    'Public Engagement',
    'Others',
  ];

  const learn_more_about = [
    'Open Access',
    'Sharing Data',
    'FAIR Data',
    'Open Source Software',
    'Sharing Code',
    'Working with GitHub',
    'PlanS',
    'Public Engagement',
    'Others',
  ];

  const help_me_with = [
    'Get connected with the Translation Team in the Turing Way',
    'Join the Carpentries in Africa',
    'Participate with R-Ladies',
    'Co-lead a session or a workshop with OSCN',
    'Learn about Coding and Best Practices',
    'Participant in Conferences',
    'Others',
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData(e.target);

    let jsonObject = {};
    formData.forEach((value, key) => {
      // Check if the key already exists in the jsonObject
      if (jsonObject.hasOwnProperty(key)) {
        if (!Array.isArray(jsonObject[key])) {
          jsonObject[key] = [jsonObject[key]];
        }
        jsonObject[key].push(value);
      } else {
        jsonObject[key] = value;
      }
    });
    // console.log(JSON.stringify(formData));
    // return;

    api
      .post('/public/registrations', jsonObject)
      .then((response) => {
        setProcessing(false);
        showToast('Submission Successful!');
        setSubmissionSuccess(true);
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.status === 409) {
          showToast('User with the email exists.');
          return;
        }
        showToast(error.response.data.message);
      });
  };

  if (submissionSuccess) {
    return <SubmissionSuccess />;
  }

  return (
    <>
      <div className="container py-5">
        <div className="container-fluid">
          <section className="mb-5">
            <h1>Join Open Science Community Nigeria (OSCN)</h1>

            <h5>Motivation</h5>
            <p>
              Inspired by the Open Science (OS) movement that prioritizes
              transparency, accessibility, and efficiency in scientific research
              and education, we founded the OSCN. Presently, there are{' '}
              <strong>NO</strong> existing Open Science communities in Nigeria
              that uphold the responsible execution of research while endorsing
              Open Science principles and practices.
            </p>

            <h5>Our Vision</h5>
            <p>
              Our aim is to mobilize and engage various groups in Nigeria,
              including scientists, researchers, clinicians, stakeholders, and
              students. We strive to encourage dialogues on Open Science,
              empowering and inspiring them to improve the quality of research
              outputs.
            </p>

            <p>
              As a community, we are a part of the International Network of Open
              Science & Scholarship Communities (INOSC). Please note that{' '}
              <strong>
                you do not require any expertise in Open Science to join the
                OSCN.
              </strong>
              Furthermore, being a part of the OSCN does not necessitate any
              commitment to specific <strong>practices.</strong>
            </p>
            <p>
              We are all gathered here to learn, exchange knowledge, and foster
              insightful discussions! Kindly adhere to our{' '}
              <a href="/code_of_conduct" target="_blank">
                Code of Conduct
              </a>{' '}
              during your participation.
            </p>
          </section>
          <section>
            <form onSubmit={handleSubmit}>
              <div className="container-fluid">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput1"
                    placeholder="Cannot be blank"
                    name="firstname"
                    required
                  />
                  <label htmlFor="floatingInput1">Firstname</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput1"
                    placeholder="Cannot be blank"
                    name="lastname"
                    required
                  />
                  <label htmlFor="floatingInput1">Lastname</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput1"
                    placeholder="Cannot be blank"
                    name="email"
                    required
                  />
                  <label htmlFor="floatingInput1">Email</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput1"
                    placeholder="Cannot be blank"
                    name="address"
                    required
                  />
                  <label htmlFor="floatingInput1">Address</label>
                </div>

                <div className="form-floating mb-4">
                  <select
                    className="form-select"
                    id="career_stage"
                    name="career_stage"
                    aria-label="Cannot be blank"
                  >
                    <option value="Phase 1 - Junior (e.g., PhD candidate, Junior Research Software Engineer)">
                      Phase 1 - Junior (e.g., PhD candidate, Junior Research
                      Software Engineer)
                    </option>
                    <option value="Phase 2 - Early  (e.g., Research Assistant/Associate, first grant holder, Lecturer, Research Software Engineer)">
                      Phase 2 - Early (e.g., Research Assistant/Associate, first
                      grant holder, Lecturer, Research Software Engineer)
                    </option>
                    <option value="Phase 3 - Mid / Recognised (e.g., Senior Lecturer, Reader, Senior Researcher, Senior Research Software Engineer, Research Software Group Leader)">
                      Phase 3 - Mid / Recognised (e.g., Senior Lecturer, Reader,
                      Senior Researcher, Senior Research Software Engineer,
                      Research Software Group Leader)
                    </option>
                    <option value="Phase 4 - Established / Experienced / Senior  (e.g., Professor, Director of Research Computing, Distinguished Engineer, Chief Data Scientist)">
                      Phase 4 - Established / Experienced / Senior (e.g.,
                      Professor, Director of Research Computing, Distinguished
                      Engineer, Chief Data Scientist)
                    </option>
                    <option value="others">Others</option>
                  </select>
                  <label htmlFor="career_stage">Career Stage</label>
                </div>
                <div className="mb-4">
                  <h5>Affiliation (University/Organisation), if existed</h5>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your answer"
                    name="affiliation"
                    required
                  />
                </div>
                <div className="mb-4">
                  <h5>What is your field of research?</h5>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="e.g., Genetics, Genomics and Bioinformatics"
                    name="research_field"
                    required
                  />
                </div>

                <div className="mb-4 mt-4">
                  <h5>You can contact me for</h5>
                  <div className="row px-4">
                    {contact_me_for.map((option, index) => {
                      const id =
                        'contact_me_for_' +
                        option.toLowerCase().replace(' ', '_') +
                        index;
                      return (
                        <div
                          className="form-check col-sm-6 col-12 col-md-4 my-2"
                          key={id}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={option}
                            id={id}
                            name="contact_me_for"
                          />
                          <label className="form-check-label" htmlFor={id}>
                            {option}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="mb-4 mt-4">
                  <h5>I would like to learn more about</h5>
                  <div className="row px-4">
                    {learn_more_about.map((option, index) => {
                      const id =
                        'learn_more_about_' +
                        option.toLowerCase().replace(' ', '_') +
                        index;
                      return (
                        <div
                          className="form-check col-sm-6 col-12 col-md-4 my-2"
                          key={id}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={option}
                            id={id}
                            name="learn_more_about"
                          />
                          <label className="form-check-label" htmlFor={id}>
                            {option}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="mb-4 mt-4">
                  <h5>I would like to learn more about</h5>
                  <div className="row px-4">
                    {help_me_with.map((option, index) => {
                      const id =
                        'help_me_with_' +
                        option.toLowerCase().replace(' ', '_') +
                        index;
                      return (
                        <div
                          className="form-check col-sm-12 col-lg-6 my-2"
                          key={id}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={option}
                            id={id}
                            name="help_me_with"
                          />
                          <label className="form-check-label" htmlFor={id}>
                            {option}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mb-4">
                  <h5>
                    How did you hear about Open Science Community Nigeria
                    (OSCN)?
                  </h5>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your answer"
                    name="hear_about_oscn"
                    required
                  />
                </div>
                <div className="mb-4">
                  <h5>
                    Is there any skill that you would like to develop in OSCN?
                  </h5>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your answer"
                    name="develop_my_skill_in"
                    required
                  />
                </div>
                <div className="mb-4">
                  <h5>Why do you want to join OSCN?</h5>
                  <textarea
                    className="form-control"
                    placeholder="Your answer"
                    style={{ height: '150px' }}
                    name="join_reason"
                    required
                  ></textarea>
                </div>

                <div className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="yes"
                    id="flexCheckDefault"
                    name="agreed_to_code_of_conduct"
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    I have read and will comply with OSCN{' '}
                    <a href="/code_of_conduct" target="_blank">
                      Code of Conduct
                    </a>
                  </label>
                </div>

                <button className="btn btn-lg btn-primary w-100" type="submit">
                  {processing ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>{' '}
                      Processing...
                    </>
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  );
}

function SubmissionSuccess() {
  return (
    <>
      <div className="container my-5">
        <div className="container-fluid">
          <h3>
            Thank you for registering with the Open Science Community Nigeria
            (OSCN)!
          </h3>
          <p>
            We're excited to welcome you to a community that's driven by the
            principles of Open Science, focusing on transparency, accessibility,
            and collaboration in research and education. As a member of OSCN,
            you're part of a movement that aims to foster meaningful
            discussions, exchange knowledge, and inspire positive change in the
            field of science.
          </p>
          <p>
            Shortly, you will receive an email confirming your registration and
            providing you with more information on how to get involved. We look
            forward to your contributions and participation in our community.
            Let's work together to promote Open Science and its values!
          </p>

          <a href="/" className="btn btn-lg btn-primary">
            Return to Home Page
          </a>
        </div>
      </div>
    </>
  );
}
