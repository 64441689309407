import React from 'react';
import boardMembers from '../../../data/boardmembers';

export default function BoardList() {
  return (
    <>
      <div className="container-fluid my-5">
        <div className="row g-3">
          {boardMembers.map((member, index) => {
            return (
              <>
                <div className="col-md-4">
                  <div className="card text-center board-member">
                    {/* <div>
                      <img
                        src={member.image}
                        alt=""
                        className="rounded-start rounded-end fill-parent"
                      />
                    </div> */}
                    <div className="card-body">
                      <h5 className="card-title mb-1 one-line">
                        {member.name}
                      </h5>
                      <p>
                        <small className="text-muted one-line mb-1">
                          {member.designation}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
