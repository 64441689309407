import React, { useEffect, useState } from 'react';
import { marked } from 'marked';
import Spinner from '../../components/Spinner';
import './styles.css';
import { setPageTitle } from '../../utils/PageTitle';

export default function COC() {
  setPageTitle('Code of Conduct');
  const [coc, setCoc] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const data = await fetch(
        'https://raw.githubusercontent.com/babasaraki/open-science-community-nigeria/main/CODE_OF_CONDUCT.md'
      )
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
        })
        .then((data) => {
          setCoc(marked(data));
        })
        .catch((error) => {
          console.error(error);
        });
      return data;
    };
    getData();
  }, []);

  if (coc === null) {
    return <Spinner />;
  }

  return (
    <>
      <section className="container mt-5">
        <h1 className="display-5">Code of Conduct</h1>
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: coc }}></div>
      </section>
    </>
  );
}
