import React from 'react';
import Posts from '../../data/posts';
import { Link } from 'react-router-dom';
import { safeText } from '../../utils/sanitizeHtml';
import { FaCalendar } from 'react-icons/fa';

export default function PostSlider() {
  return (
    <div className="grid-container mt-4 mb-5">
      {Posts.map((post, index) => {
        return (
          <div className="card slider-card" key={index}>
            <Link
              to={
                '/posts/' + encodeURIComponent(post.title.replaceAll(' ', '-'))
              }
            >
              <div
                className="card-img-top"
                style={{
                  backgroundImage: `url(${post.image})`,
                  backgroundSize: 'cover',
                }}
              ></div>
              <div className="card-body">
                <h5 className="card-title post-card-title">{post.title}</h5>
                <p className="card-text post-card-text">
                  {safeText(post.content)}
                </p>
                <hr className="m-0 mt-2 mb-1" />
                <div className="d-flex" style={{ fontSize: '13px' }}>
                  <small className="text-muted my-2 me-3">
                    <FaCalendar style={{ marginRight: '10px' }} /> {post.date}
                  </small>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
}
