import Carousel from './Carousel';
import PostSlider from '../Posts/posts-slider';
import SideBar from '../../components/Sidebar';
import { setPageTitle } from '../../utils/PageTitle';

export default function Home() {
  setPageTitle();
  return (
    <div>
      <Carousel />
      <div className="container pt-5">
        <div
          className="container-fluid row justify-content-between"
          style={{ margin: '0px' }}
        >
          <div className="col-lg-8 main-content">
            <h1 className="text-primary">Resources and News</h1>
            <p>
              Our community is made up of researchers, scientists, and
              individuals who share a passion for open science. By connecting
              with our members, you can collaborate on open science projects and
              stay informed on the latest news and developments related to open
              science in Nigeria.
            </p>
            <PostSlider />
          </div>
          <SideBar />
        </div>
      </div>
    </div>
  );
}
