import React from 'react';
import TeamMembers from './TeamMembers';
import core_team from '../../assets/core-team.jpg';
import { setPageTitle } from '../../utils/PageTitle';

export default function CoreTeam() {
  setPageTitle('Core Team');
  return (
    <>
      <div
        className="container-fluid img-banner"
        style={{
          backgroundImage: `url(${core_team})`,
        }}
      ></div>
      <div className="container py-5">
        <div className="container-fluid">
          <h1 className="display-5">Our Core Team</h1>
          <p className="text-muted">
            Meet the dedicated team of scientists and researchers behind Open
            Science Community Nigeria (OSCN). Our core team is comprised of
            passionate advocates for open science practices, who are committed
            to increasing the quality and impact of research in Nigeria. With a
            shared vision of promoting transparency, reproducibility, and
            collaboration in scientific research, our team works tirelessly to
            provide educational resources, advocacy efforts, and foster
            collaborations within the scientific community. Get to know our core
            team and learn about their diverse backgrounds and expertise.
          </p>
        </div>
        <TeamMembers />
        <div className="container-fluid border-top pt-4">
          <small className="text-muted">
            Join our vibrant community of like-minded individuals committed to
            advancing the principles of Open Science in Nigeria. Be part of the
            core team today and help transform research and innovation in
            Nigeria!
          </small>
        </div>
      </div>
    </>
  );
}
