import React from 'react';
import { Link } from 'react-router-dom';
import UNESCO_logo from '../../assets/UNESCO.webp';
import { setPageTitle } from '../../utils/PageTitle';

export default function UNESCO() {
  setPageTitle('UNESCO Recommendation on Open Science');
  return (
    <>
      <div className="container mt-5">
        <div>
          <h1>UNESCO Recommendation on Open Science</h1>
          <p>
            The UNESCO recentely published its recommendation on Open Science,
            which was adopted by the 41st session of UNESCO General Conference
            in November 2021. You can read the{' '}
            <Link
              to="https://unesdoc.unesco.org/ark:/48223/pf0000379949.locale=en"
              target="_blank"
            >
              UNESCO Recommendation on Open Science here.
            </Link>
          </p>
        </div>
        <div className="container-fluid d-sm-flex my-5 py-5">
          <div>
            <img
              src={UNESCO_logo}
              style={{ width: '200px' }}
              alt="UNESCO logo"
            />
          </div>
          <div className="my-auto px-sm-3 pt-4">
            <p>
              Supporting scientists and other societal actors in accumulating
              and using open data resources in a transdisciplinary mode to
              maximize scientific, social, economic, and cultural benefits, and
              stimulate the creation of hybrid disciplinary collaborative spaces
              where scientists from different disciplines interact with software
              developers, coders, creatives, innovators, engineers, and artists,
              among others.
            </p>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
}
