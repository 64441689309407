import React from 'react';
import PostGrid from './posts-grid';
import { setPageTitle } from '../../utils/PageTitle';

export default function Posts() {
  setPageTitle('Posts');
  return (
    <>
      <div className="container">
        <PostGrid />
        <p className="text-muted pt-4 border-top">
          <small>
            We welcome posts on a wide range of topics related to open science,
            such as data sharing, open access publishing, transparency in
            research, reproducibility, and more. We also encourage posts that
            share personal experiences and reflections on open science, as well
            as posts that offer tips and advice for others in the community.
          </small>
        </p>
      </div>
    </>
  );
}
