import React from 'react';
import { Link } from 'react-router-dom';
import { setPageTitle } from '../../utils/PageTitle';

export default function OurStory() {
  setPageTitle('Our Story');
  return (
    <>
      <section className="container mt-5 py-3">
        <h1 className="display-5">Our Story</h1>
        <p>
          Welcome to Open Science Community Nigeria, a nonprofit organization
          committed to promoting open science practices in Nigeria. Inspired by
          the Open Science (OS) movement that prioritizes transparency,
          accessibility, and efficiency in scientific research and education, we
          founded the OSCN. Presently, there are NO existing Open Science
          communities in Nigeria that uphold the responsible execution of
          research while endorsing Open Science principles and practices.
        </p>

        <p>
          We aim to create a culture of openness and accountability in Nigerian
          science by advocating for the adoption of open science principles and
          practices. At Open Science Community Nigeria, we believe that science
          can only progress if research is conducted transparently and
          reproducibly. By embracing open science practices, researchers can
          increase the quality and impact of their research, as well as foster a
          collaborative and inclusive scientific culture.
        </p>

        <p>
          Our scientist-driven organization is dedicated to advancing science
          through education, advocacy, and collaboration. We work tirelessly to
          promote open science practices by providing educational resources and
          training programs to researchers, advocating for open science policies
          and practices at institutional and governmental levels, and fostering
          collaborations among researchers, institutions, and other stakeholders
          in the scientific community.
        </p>
      </section>
      <section className="container-fluid bg-gray-100 py-3 m-0">
        <div className="container">
          <h3>Our Vision</h3>
          <p>
            Our aim is to mobilize and engage various groups in Nigeria,
            including scientists, researchers, clinicians, stakeholders, and
            students. We strive to encourage dialogues on Open Science,
            empowering and inspiring them to improve the quality of research
            outputs. As a community, we are a part of the International Network
            of Open Science & Scholarship Communities (INOSC). Please note that{' '}
            <strong>
              you do not require any expertise in Open Science to join the OSCN.
            </strong>{' '}
            Furthermore, being a part of the OSCN does not necessitate any
            commitment to specific practices. We are all gathered here to learn,
            exchange knowledge, and foster insightful discussions! Kindly adhere
            to our <Link to="/code_of_conduct">Code of Conduct</Link> during
            your participation.
          </p>

          <p>
            <Link to="/join">
              Join Us as we collaborate towards integrating Open Science
              practices in Nigeria.
            </Link>
          </p>
        </div>
      </section>
    </>
  );
}
