import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import events_json from '../../data/events.json';
import './styles.css';

export default function Sidebar() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(
      events_json.filter((event) => {
        const now = new Date();
        const event_date = new Date(event.date);
        if (event_date > now) {
          return true;
        }
        return false;
      })
    );
  }, []);

  return (
    <>
      <div className="col-lg-3 side-bar">
        <div className="mb-4">
          <h4>Upcoming Open Science Community Events</h4>
          <small className="text-muted">
            Join our open science community for upcoming events promoting
            transparency, collaboration, and inclusivity in research.
          </small>
        </div>
        <EventCards events={events} />
      </div>
    </>
  );
}

function EventCards({ events }) {
  if (events.length === 0) {
    return (
      <div className="list-group list-group-flush border-top border-bottom">
        <div className="w-100 align-items-center justify-content-between py-3">
          <strong>No Upcoming Events!</strong>
          <p className="text-muted">
            <small>
              We're sorry, but it looks like there are no upcoming events
              scheduled to hold soon. Please check back soon for updates.
            </small>
          </p>
        </div>
      </div>
    );
  }
  return (
    <>
      {events.map((event, index) => {
        return (
          <div
            className="list-group list-group-flush border-top border-bottom"
            key={index}
          >
            <a
              href={event.url}
              className="list-group-item list-group-item-action lh-tight "
              target="_blank"
              rel="noreferrer"
            >
              <div className="w-100 align-items-center justify-content-between">
                <strong className="mb-1">{event.title}</strong> <br />
                <small className="text-muted">{event.date}</small>
              </div>
              <div className="col-12 mb-1 small">{event.description}</div>
            </a>
          </div>
        );
      })}
    </>
  );
}
