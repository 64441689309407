import React from 'react';
import { Link } from 'react-router-dom';
import slider1 from '../assets/images/Open-Science-Community-Nigeria-conference-001.jpg';
import slider2 from '../assets/images/Open-Science-Community-Nigeria-conference-002.jpg';
import slider3 from '../assets/images/Open-Science-Community-Nigeria-conference-003.jpg';
import './styles.css';

export default function Carousel() {
  return (
    <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="1"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="2"
        ></button>
      </div>

      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={slider1} alt="" className="bd-placeholder-img" />
          <div className="container">
            <div className="carousel-caption text-start">
              <h1>Welcome to Open Science Community Nigeria.</h1>
              <p>
                Our mission at Open Science Community Nigeria is to promote the
                adoption of open science principles and practices in Nigeria. We
                believe that science can only progress if research is conducted
                transparently and reproducibly. By joining our community, you
                can help us create a culture of openness and accountability in
                Nigerian science.
              </p>
              <p>
                <Link className="btn btn-lg btn-primary" to="/our-story">
                  Learn More
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src={slider2} alt="" className="bd-placeholder-img" />
          <div className="container">
            <div className="carousel-caption text-start">
              <h1>Promoting open science practices in Nigeria.</h1>
              <p>
                We are a scientist-driven nonprofit organization dedicated to
                advancing science through the principles of open science. Our
                mission is to promote open science practices in Nigeria through
                education, advocacy, and collaboration. We work towards creating
                a transparent and reproducible scientific culture in Nigeria,
                and we invite you to join us in this journey. Get involved today
                and help us make science better.
              </p>
              <p>
                <Link className="btn btn-lg btn-primary" to="/join">
                  Get Involved
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src={slider3} alt="" className="bd-placeholder-img" />
          <div className="container">
            <div className="carousel-caption text-start">
              <h1>Empowering researchers, increasing trust in science.</h1>
              <p>
                Our work at Open Science Community Nigeria has far-reaching
                impacts on the scientific community in Nigeria. By promoting
                open science practices, we are empowering researchers to conduct
                transparent and reproducible research, increasing trust in
                science, and advancing knowledge for a better future. We rely on
                your support to continue our mission, so please consider
                donating to our cause today.
              </p>
              <p>
                <Link className="btn btn-lg btn-primary" to="/join">
                  Support Our Work
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
