import React from 'react';
import { setPageTitle } from '../../utils/PageTitle';

export default function Contact() {
  setPageTitle('Contact Us');
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    event.target.reset();
  };
  return (
    <>
      <div className="container mt-5">
        <div className="container-fluid">
          <h1 className="display-5">Contact OSCN</h1>
          <p>
            We value your interest and engagement with our organization. Whether
            you have a question, suggestion, or would like to collaborate with
            us, this is the place to connect. We are passionate about promoting
            open science practices and fostering a culture of transparency and
            collaboration in Nigerian science. We encourage you to reach out to
            us using the contact information provided below.
          </p>

          <p>
            <small className="text-muted">
              Your feedback and inquiries are essential to our mission of
              advancing open science in Nigeria. We look forward to hearing from
              you and working together to create a vibrant and inclusive
              scientific community.
            </small>
          </p>
        </div>
        <div className="conatiner">
          <form onSubmit={handleSubmit}>
            <div className="container-fluid">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput1"
                  placeholder="John Doe"
                  name="name"
                />
                <label htmlFor="floatingInput1">Your Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput2"
                  placeholder="name@example.com"
                  name="email"
                />
                <label htmlFor="floatingInput2">Your Email</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput3"
                  placeholder="Subject"
                  name="subject"
                />
                <label htmlFor="floatingInput3">Subject</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Your Message"
                  id="floatingTextarea2"
                  style={{ height: '100px' }}
                  name="message"
                ></textarea>
                <label htmlFor="floatingTextarea2">Your Message</label>
              </div>
              <input
                type="submit"
                className="form-control btn btn-lg btn-primary"
                value="Submit"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
