import React from 'react';
import BoardList from './BoardList';
import './styles.css';
import { setPageTitle } from '../../utils/PageTitle';

export default function AdvisoryBoard() {
  setPageTitle('Advisory Board');
  return (
    <>
      <div className="container mt-5">
        <div className="container-fluid">
          <h1 className="display-5">The board of OSCN</h1>
          <p>
            Our Board of Advisory consists of distinguished scientists,
            researchers, educators, and advocates who share a common passion for
            promoting transparency, accessibility, and collaboration in
            scientific research. They play a pivotal role in shaping OSCN's
            strategic direction, ensuring that our initiatives align with the
            evolving landscape of open science and its potential impact on
            Nigeria's scientific community.
          </p>

          <p>
            Each board member brings a unique perspective and expertise to the
            table, enriching the collective knowledge and insights that drive
            our organization forward. They have demonstrated unwavering
            dedication to the principles of open science and have made
            significant contributions to their respective fields, both
            nationally and internationally.
          </p>

          <p>
            Our board members serve as champions for open science, leveraging
            their influence and networks to advocate for open science policies
            and practices in Nigeria. Through their collective efforts, they
            actively contribute to fostering a culture of openness,
            collaboration, and accountability in Nigerian science.
          </p>
          <p>
            We are honored to have such a distinguished group of individuals on
            our Board of Advisory, and we extend our deepest gratitude for their
            commitment to our mission. Together, we strive to create a vibrant
            and inclusive open science community in Nigeria that accelerates
            scientific progress and addresses societal challenges through
            transparent and reproducible research.
          </p>
        </div>
        <BoardList />
      </div>
    </>
  );
}
