import React from 'react';
import oscn_logo from '../../assets/oscn_logo.png';
import { useLocation } from 'react-router-dom';
import './styles.css';
import global from '../../data/global.json';
import inosc_logo from '../../assets/inosc.png';

export default function Navbar() {
  const { pathname } = useLocation();
  return (
    <>
      {/* BEFORE NAV */}
      <div className="px-5 py-3 d-md-flex">
        <div className="mx-md-5">
          <a href="/">
            <img
              src={oscn_logo}
              alt={global.brand.name + "'s Logo"}
              style={{ width: '12rem' }}
            />
          </a>
        </div>
        <div className="mt-md-0 mt-sm-3">
          <h1 className="display-6 text-primary">{global.brand.name}</h1>
          <p className="display-6" style={{ fontSize: '18px' }}>
            {global.brand.slogan}
          </p>
        </div>
      </div>

      {/* NAV BAR */}
      <nav className="navbar sticky-top navbar-expand-md px-lg-5 px-md-3 px-sm-0 py-lg-3 py-md-3 py-sm-2">
        <div className="container-fluid">
          <button
            className="navbar-toggler bg-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon text-light"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <div className="navbar-nav ms-auto px-3 ">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      pathname.replace('/', '') === '' ? 'active' : ''
                    }`}
                    aria-current="page"
                    href="/"
                  >
                    Home
                  </a>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className={`nav-link dropdown-toggle ${
                      ['our-story', 'core-team'].includes(
                        pathname.replace('/', '')
                      )
                        ? 'active'
                        : ''
                    }`}
                    id="avbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    About
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a
                        className={`dropdown-item ${
                          pathname.replace('/', '') === 'our-story'
                            ? 'active'
                            : ''
                        }`}
                        href="/our-story"
                      >
                        Our Story
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          pathname.replace('/', '') === 'core-team'
                            ? 'active'
                            : ''
                        }`}
                        href="/core-team"
                      >
                        Our Core Team
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className={`nav-link dropdown-toggle ${
                      ['posts', 'events', 'newsletter'].includes(
                        pathname.replace('/', '')
                      )
                        ? 'active'
                        : ''
                    }`}
                    id="avbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    What We Do
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a
                        className={`dropdown-item ${
                          pathname.replace('/', '') === 'posts' ? 'active' : ''
                        }`}
                        href="/posts"
                      >
                        News, Blog and Podcast
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          pathname.replace('/', '') === 'events' ? 'active' : ''
                        }`}
                        href="/events"
                      >
                        Upcoming Events
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className={`dropdown-item ${
                          pathname.replace("/", "") === "newsletter"
                            ? "active"
                            : ""
                        }`}
                        href="/newsletter"
                      >
                        Newsletter
                      </a>
                    </li> */}
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className={`nav-link dropdown-toggle ${
                      [
                        'join',
                        'members',
                        'advisory_board',
                        'code_of_conduct',
                      ].includes(pathname.replace('/', ''))
                        ? 'active'
                        : ''
                    }`}
                    id="avbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Community
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a
                        className={`dropdown-item ${
                          pathname.replace('/', '') === 'join' ? 'active' : ''
                        }`}
                        href="/join"
                      >
                        Join OSCN
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className={`dropdown-item ${
                          pathname.replace('/', '') === 'members'
                            ? 'active'
                            : ''
                        }`}
                        href="/members"
                      >
                        Members
                      </a>
                    </li> */}
                    <li>
                      <a
                        className={`dropdown-item ${
                          pathname.replace('/', '') === 'advisory_board'
                            ? 'active'
                            : ''
                        }`}
                        href="/advisory_board"
                      >
                        Advisory Board
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          pathname.replace('/', '') === 'code_of_conduct'
                            ? 'active'
                            : ''
                        }`}
                        href="/code_of_conduct"
                      >
                        Code of Conduct
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      pathname.replace('/', '') === 'unesco_recommendation'
                        ? 'active'
                        : ''
                    }`}
                    href="/unesco_recommendation"
                  >
                    UNESCO Recommendation
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      pathname.replace('/', '') === 'contact' ? 'active' : ''
                    }`}
                    href="/contact"
                  >
                    Contact
                  </a>
                </li>
              </ul>
              <a
                href="https://osc-international.com/"
                className="affiliate_brand"
              >
                <img src={inosc_logo} alt="osc-international" />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
