import React, { useState } from 'react';
import {
  FaEnvelope,
  FaGithub,
  FaLinkedin,
  FaMastodon,
  FaOrcid,
  FaTwitter,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import team from '../../../data/team';
import './styles.css';

export default function TeamMembers() {
  let [memeberdata, setMemberdata] = useState(team[0]);
  const social_icons = {
    linkedin: <FaLinkedin />,
    twitter: <FaTwitter />,
    github: <FaGithub />,
    mastodon: <FaMastodon />,
    ORCID: <FaOrcid />,
    email: <FaEnvelope />,
  };

  function viewMember(member) {
    setMemberdata(member);
  }

  return (
    <>
      <div className="container-fluid team-container py-4">
        {team.map((member, index) => {
          return (
            <div
              className="card team-member"
              key={index}
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => viewMember(member)}
            >
              <div className="row g-1">
                <div
                  className="col-md-5"
                  style={{
                    minHeight: '16rem',
                  }}
                >
                  <img
                    src={member.image}
                    alt=""
                    className="rounded-start rounded-end fill-parent"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body">
                    <h5 className="card-title mb-1 one-line">{member.name}</h5>
                    <p className="">
                      <small className="text-muted one-line mb-1">
                        {member.designation}
                      </small>
                      <small className="card-text bio ">{member.bio}</small>
                    </p>
                    <div className="d-flex gap-2 border-top pt-1">
                      {Object.entries(member.social).map((arr, index) => {
                        if (arr[1] === 'nil') {
                          return null;
                        }
                        return (
                          <Link to={arr[1]} key={index}>
                            {social_icons[arr[0]]}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {memeberdata.name}'s Profile
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-5">
              <h1 className="display-5">{memeberdata.name}</h1>
              <small className="text-muted">{memeberdata.designation}</small>
              <p className="mt-3">{memeberdata.bio}</p>
              <div className="d-flex gap-2 border-top pt-1">
                {Object.entries(memeberdata.social).map((arr, index) => {
                  if (arr[1] === 'nil') {
                    return null;
                  }
                  return (
                    <Link to={arr[1]} key={index}>
                      {social_icons[arr[0]]}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
