const boardMembers = [
  { name: 'Prof. Chidi Obi', designation: 'Scientific Advisor' },
  { name: 'Dr. Fatima Okoro', designation: 'Research Director' },
  { name: 'Engr. Ibrahim Aliyu', designation: 'Technology Specialist' },
  { name: 'Dr. Amara Nwachukwu', designation: 'Data Analytics Advisor' },
  { name: 'Dr. Umar Abubakar', designation: 'Health and Medicine Advisor' },
  {
    name: 'Engr. Oluwaseun Adeyemi',
    designation: 'Innovation and Entrepreneurship Expert',
  },
];

export default boardMembers;
