import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NavBar from './components/Navbar';
import Footer from './components/Footer';

import Home from './pages/Home';
import OurStory from './pages/OurStory';
import Posts from './pages/Posts';
import SinglePost from './pages/Posts/SinglePost';
import CoreTeam from './pages/CoreTeam';
import Events from './pages/Events';
import NotFound from './pages/404';
import ScrollToTop from './utils/ScrollToTop';
import COC from './pages/COC';
import UNESCO from './pages/UNESCO_Recommendation';
import Join from './pages/Join';
import AdvisoryBoard from './pages/AdvisoryBoard';
import Contact from './pages/Contact';
import { Toast } from './components/Toast';

function App() {
  return (
    <>
      <div className="body">
        <div className="wrapper">
          <NavBar />
          <ScrollToTop />
          <Toast />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/core-team" element={<CoreTeam />} />
            <Route path="/posts" element={<Posts />} />
            <Route path="/posts/:title" element={<SinglePost />} />
            <Route path="/events" element={<Events />} />
            <Route path="/code_of_conduct" element={<COC />} />
            <Route path="/unesco_recommendation" element={<UNESCO />} />
            <Route path="/join" element={<Join />} />
            <Route path="/advisory_board" element={<AdvisoryBoard />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
